<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-row>
        <v-col>
          <v-btn
            color="teal"
            class="mr-2"
            outlined
            @click="showDialogSearch = true"
          >
            <v-icon
              left
              v-text="'mdi-magnify'"
            />
            Pesquisar
          </v-btn>
          <v-btn
            color="cyan"
            class="mr-2"
            outlined
            @click="showDialogSendCertificateEmail = true"
          >
            <v-icon
              left
              v-text="'mdi-send'"
            />
            Enviar e-mail certificado
          </v-btn>
          <v-btn
            color="secondary"
            class="mr-2"
            outlined
            :loading="loadingExport"
            @click="exportEnrolled()"
          >
            <v-icon
              left
              v-text="'mdi-export'"
            />
            Exportar
          </v-btn>
          <v-btn
            color="secondary"
            class="mr-2"
            outlined
            @click="showDialogImportEnrolled = true"
          >
            <v-icon
              left
              v-text="'mdi-import'"
            />
            Importar
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="enrolled"
              :options.sync="pagination"
              :items-per-page="50"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, 250, 500, 1000]
              }"
              @update:options="loadEnrolled"
            >
              <template
                v-slot:[`item.createdAt`]="{ item }"
              >
                {{ item.createdAt | date('DD/MM/YYYY HH:mm') }}
              </template>
              <template
                v-slot:[`item.showedUpDate`]="{ item }"
              >
                {{ item.showedUpDate | date('DD/MM/YYYY HH:mm') }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  @click="openDialog(item.id)"
                >
                  <v-icon
                    v-text="'mdi-eye'"
                    small
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-enrolled
      :key="viewEnrolledId"
      v-model="showDialogEnrolled"
      :enrolled-id="viewEnrolledId"
      :event-id="parseInt($route.params.id)"
      @confirm-presence="loadEnrolled"
      @save="loadEnrolled"
    />

    <dialog-enrolled-search
      v-model="showDialogSearch"
      @show-dialog-enrolled="openDialog"
      @search="loadEnrolled"
    />

    <dialog-import-enrolled
      v-model="showDialogImportEnrolled"
      :event-id="parseInt($route.params.id)"
      @imported="onImport"
    />

    <dialog-enrolled-send-certificate-email
      v-model="showDialogSendCertificateEmail"
      :event-id="parseInt($route.params.id)"
    />

  </div>
</template>

<script>
  import enrolledApi from '@/api/enrolled'

  export default {

    components: {
      DialogEnrolled: () => import('@/components/dialog/events/DialogEnrolled'),
      DialogEnrolledSearch: () => import('@/components/dialog/events/DialogEnrolledSearch'),
      DialogImportEnrolled: () => import('@/components/dialog/events/DialogImportEnrolled'),
      DialogEnrolledSendCertificateEmail: () => import('@/components/dialog/events/DialogEnrolledSendCertificateEmail')
    },

    data () {
      return {
        loading: false,
        loadingExport: false,
        showDialogEnrolled: false,
        showDialogImportEnrolled: false,
        showDialogSendCertificateEmail: false,
        showDialogSearch: false,
        viewEnrolledId: undefined,
        count: 0,
        headers: [
          { text: '#', value: 'id' },
          { text: 'Nome', value: 'name' },
          { text: 'Tipo', value: 'enrolledType.description' },
          { text: 'Data Inscrição', value: 'createdAt' },
          { text: 'Data Presença', value: 'showedUpDate' },
          { text: '', value: 'actions', align: 'right' },
        ],
        enrolled: [],
        pagination: {},
      }
    },

    methods: {
      async loadEnrolled (filters = {}) {
        try {
          this.loading = true

          const response = await enrolledApi.list(this.$route.params.id, {
            ...filters,
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
          })

          this.enrolled = response.data.enrolled
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async exportEnrolled () {
        try {
          this.loadingExport = true

          const response = await enrolledApi.downloadCsv(this.$route.params.id)

          const linkSource = `data:text/plain;base64,${response.data.file}`
          const downloadLink = document.createElement('a')
          const fileName = `famurs_inscritos_evento_${this.$route.params.id}.csv`

          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingExport = false
        }
      },

      openDialog(id) {
        this.viewEnrolledId = id
        this.showDialogEnrolled = true
      },

      onImport() {
        this.showDialogImportEnrolled = false
        this.$snackbar.show({ color: 'success', message: 'Importado com sucesso!' })
        this.loadEnrolled()
      },

    },

  }
</script>
