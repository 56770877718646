import axios from '@/plugins/axios'

export default {
  async list(eventId, data) {
    const params = new URLSearchParams(data).toString();
    return await axios.get(`events/${eventId}/enrolled?${params}`);
  },

  async get(eventId, id) {
    return await axios.get(`events/${eventId}/enrolled/${id}`);
  },

  async insert(eventId, data) {
    return await axios.post(`events/${eventId}/enrolled`, data);
  },

  async update(eventId, id, data) {
    return await axios.put(`events/${eventId}/enrolled/${id}`, data);
  },

  async confirmPresence(eventId, id) {
    return await axios.patch(
      `events/${eventId}/enrolled/${id}/confirm-presence`
    );
  },

  async badge(eventId, id) {
    return await axios.get(`events/${eventId}/enrolled/${id}/badge`);
  },

  async certificate(eventId, id) {
    return await axios.get(`events/${eventId}/enrolled/${id}/certificate`);
  },

  async certificateSendEmailToAll(eventId) {
    return await axios.post(
      `events/${eventId}/enrolled/send-email-certificate`
    );
  },

  async certificateSendEmail(eventId, id) {
    return await axios.post(
      `events/${eventId}/enrolled/${id}/certificate/send-email`
    );
  },

  async downloadCsv(eventId) {
    return await axios.get(`events/${eventId}/enrolled/download-csv`);
  },

  async import(eventId, data) {
    return await axios.post(`events/${eventId}/enrolled/import`, data);
  }
};
